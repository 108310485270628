import INITIAL_STATE from './initialState';

export const FETCH_ITEMS = 'FETCH_ITEMS';
export const FETCH_ITEMS_SUCCESS = 'FETCH_ITEMS_SUCCESS';
export const FETCH_ITEMS_LOAD_MORE_SUCCESS =
  'FETCH_ITEMS_LOAD_MORE_SUCCESS';
export const FETCH_ITEMS_ERROR = 'FETCH_ITEMS_ERROR';

const reducer = (state = INITIAL_STATE, action = null) => {
  switch (action.type) {
    case FETCH_ITEMS: {
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    }

    case FETCH_ITEMS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        totalElements: action.payload.totalItemsCount,
        content: [...action.payload.data],
      };
    }

    case FETCH_ITEMS_LOAD_MORE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        totalElements: action.payload.totalItemsCount,
        content: [...state.content, ...action.payload.data],
      };
    }

    case FETCH_ITEMS_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        isLoading: false,
        content: [],
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
