import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import useQueryString from 'hooks/useQueryString';
import { DEFAULT_PAGINATION } from 'constants/app.constants';
import { DEFAULT_VALUES } from './FiltersForm.config';
import LocationFilterForm from './LocationFiltersForm';
const FilterFormContainer = ({
  filters,
}) => {
  const { setQueryParams } = useQueryString();

  const handleFilterSubmitAndReset = useCallback(
    data => {
      setQueryParams({ filters: data, pageNumber: 1, pageSize: DEFAULT_PAGINATION.PAGE_SIZE });
    },
    [setQueryParams],
  );

  const defaultValues = useMemo(() => ({ ...DEFAULT_VALUES, ...filters }), [filters]);

  return (
    <LocationFilterForm
      onSubmit={handleFilterSubmitAndReset}
      onReset={handleFilterSubmitAndReset}
      defaultValues={defaultValues}
    />
  );
};

FilterFormContainer.propTypes = {
  filters: PropTypes.shape({}),
};

FilterFormContainer.defaultProps = {
  filters: null,
};

export default FilterFormContainer;
