// eslint-disable-next-line no-restricted-imports
import styled from 'styled-components';
import { BusyIndicator as UIBusyIndicator } from '@ui5/webcomponents-react';

const BusyIndicator = styled(UIBusyIndicator)`
  min-width: 100%;
  min-height: 100%;
`;

export default BusyIndicator;
