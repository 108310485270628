import { Redirect } from 'react-router';
import { route } from '../../../routeConfig';
import Container from '../Container';

export default [
  {
    path: '/',
    component: () => <Redirect to={route} />,
    exact: true,
  },
  {
    path: route,
    component: Container,
    exact: true,
  },
];
