import PropTypes from 'prop-types';
import { Button, IllustratedMessage, IllustrationMessageType } from '@ui5/webcomponents-react';
import withTranslation from 'hocs/withTranslation';

const DataLoadError = ({ t, onRefresh }) => (
  <IllustratedMessage
    name={IllustrationMessageType.UnableToLoad}
    titleText={'Unable to load data'}
    subtitleText={'Please refresh or try again later.'}
  >
    <Button data-testid="dataLoadErrorRefreshButton" onClick={onRefresh}>
      {'Refresh'}
    </Button>
  </IllustratedMessage>
);

export default withTranslation(DataLoadError);

DataLoadError.propTypes = {
  t: PropTypes.func.isRequired,
  onRefresh: PropTypes.func,
};

DataLoadError.defaultProps = {
  onRefresh: () => window.location.reload(),
};
