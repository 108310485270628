import { useSelector, useDispatch } from 'react-redux';
import { DEFAULT_VALUES } from 'features/Inventory/FiltersForm/FiltersForm.config';
import {
  setInputValue,
  addMultiInputToken,
  removeToken,
  resetFilters,
  addMultiComboBoxTokens,
} from '../features/Inventory/FiltersForm/state/filters.actions';
import { filtersSelector } from '../features/Inventory/FiltersForm/state/filters.reducer';

export default () => {
  const tokenValues = useSelector(filtersSelector);
  const dispatch = useDispatch();

  const setInputValueToFilter = (value, name) => {
    const payload = {
      name,
      value,
    };
    dispatch(setInputValue(payload));
  };
  const addMultiInputTokenToFilter = (value, name) => {
    const payload = {
      name,
      value,
    };
    dispatch(addMultiInputToken(payload));
  };

  const addMultiComboBoxValuesToTokenState = (values, name) => {
    const payload = {
      name,
      values,
    };
    dispatch(addMultiComboBoxTokens(payload));
  };

  const removeTokenFromFilter = (value, name) => {
    const payload = {
      name,
      value,
    };
    dispatch(removeToken(payload));
  };

  const getFilterValues = () => {
    const formFieldsWithValuesViaTokens = { ...DEFAULT_VALUES };

    if (tokenValues && Object.keys(tokenValues).length > 0) {
      Object.keys(formFieldsWithValuesViaTokens).forEach(id => {
        const value = tokenValues[id];
        if (
          typeof value === 'undefined' ||
          value === null ||
          value.length === null ||
          value.length === 0
        ) {
          delete formFieldsWithValuesViaTokens[id];
        } else {
          formFieldsWithValuesViaTokens[id] = tokenValues[id];
        }
      });
    }
    return formFieldsWithValuesViaTokens;
  };

  const resetFilterValues = () => {
    dispatch(resetFilters());
  };

  return {
    setInputValueToFilter,
    addMultiInputTokenToFilter,
    removeTokenFromFilter,
    getFilterValues,
    resetFilterValues,
    addMultiComboBoxValuesToTokenState,
    tokenValues,
  };
};
