import { useEffect, useCallback } from 'react';
import useQueryString from 'hooks/useQueryString';
import PropTypes from 'prop-types';
import { inventoryAction } from './state/Table.actions';
import {
  Title,
  Grid,
} from '@ui5/webcomponents-react';
import { useDispatch, useSelector } from 'react-redux';
import { inventorySelector } from './state/Table.selectors';
import InventoryCard from './InventoryCard';
import NoDataComponent from 'components/NoDataComponent';

const InventoryTableContainer = ({ tableObject }) => {
  const dispatch = useDispatch();
  const { getQueryParams } = useQueryString();
  const queryParams = getQueryParams();

  const fetchRecords = useCallback(() => {
    dispatch(inventoryAction(queryParams));
  }, [dispatch, queryParams]);

  useEffect(() => {
    //fetchRecords();
    const interval = setInterval(() => {
      fetchRecords();
    }, 1000)
    return () => clearInterval(interval)
  }, [fetchRecords, queryParams]);

  let { content: data} = useSelector(inventorySelector);

  if (data === null || data.length===0) {
    return <NoDataComponent/>;
  }
  return (
    <div>
      <Title level='H5' style={{color:'grey'}}>Ingredient Inventory Levels</Title>
      <Grid style={{ display: 'inline' }}>
        {data.map((item) => {
          return (
            <InventoryCard item={item} key={item.id}/>
          );
        })}
      </Grid>
    </div>
  );
};

InventoryTableContainer.propTypes = {
  tableObject: PropTypes.object.isRequired,
};

export default InventoryTableContainer;
