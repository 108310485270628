import { setTheme } from '@ui5/webcomponents-base/dist/config/Theme';
import { ThemeProvider, ShellBar } from '@ui5/webcomponents-react';
import '@ui5/webcomponents-react/dist/Assets';
import eureka from 'eureka';
import routesConfig from 'features/routes.config';
import { createBrowserHistory } from 'history';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import { Router, Switch } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import DataLoadError from 'components/DataLoadError';
import store from './state/store';
import logo from '../assets/logo';
import * as S from './styled';

const App = ({ history }) => {

  return (
    <ThemeProvider>
      <Provider store={store}>
        <Router history={history}>
          <ErrorBoundary FallbackComponent={DataLoadError}>
            <S.ShellBar
              logo={
                <>
                  <img
                    alt="SAP Logo"
                    src="https://sap.github.io/ui5-webcomponents/assets/images/sap-logo-svg.svg"
                    style={{backgroundColor:'white'}}
                  />
                  <img
                    alt="STB Logo"
                    src={logo}
                    style={{backgroundColor:'white'}}
                  />
                </>
              }
            />
            <Switch>{renderRoutes(routesConfig)}</Switch>
          </ErrorBoundary>
        </Router>
      </Provider>
    </ThemeProvider>
  );
};

App.propTypes = {
  history: PropTypes.object,
};

App.defaultProps = {
  history: createBrowserHistory(),
};

export default App;
