import { forwardRef } from 'react';
import useTranslate from 'hooks/useTranslate';

const withTranslation = Component =>
  forwardRef((props, ref) => {
    const translate = useTranslate();

    return <Component {...props} t={translate} ref={ref} />;
  });

export default withTranslation;
