import { DEFAULT_PAGINATION } from 'constants/app.constants';
import { getInventory } from 'services/inventory.api';
import {
  FETCH_ITEMS,
  FETCH_ITEMS_SUCCESS,
  FETCH_ITEMS_LOAD_MORE_SUCCESS,
  FETCH_ITEMS_ERROR,
} from './Table.reducer';

export const fetchInventory = () => ({
  type: FETCH_ITEMS,
});

export const fetchInventorySuccess = payload => ({
  type: FETCH_ITEMS_SUCCESS,
  payload,
});

export const fetchInventoryLoadMoreSuccess = payload => ({
  type: FETCH_ITEMS_LOAD_MORE_SUCCESS,
  payload,
});

export const fetchInventoryError = error => ({
  type: FETCH_ITEMS_ERROR,
  payload: { error },
});

export const inventoryAction = ({
  pageNumber = 1,
  pageSize = DEFAULT_PAGINATION.PAGE_SIZE,
  asc,
  orderBy,
  filters,
}) => async dispatch => {
  const _filtersWithValues = {};
  if (filters) {
    const keys = Object.keys(filters);
    const values = Object.values(filters);
    for (let i = 0; i < keys.length; i += 1) {
      if (values[i] && values[i].length > 0) {
        _filtersWithValues[keys[i]] = values[i];
      }
    }
  }
  const _pageNumber = pageNumber;
  const _pageSize = pageSize;
  let _sort = null;
  let _order = null;
  if (orderBy) {
    _sort = orderBy;
    _order = asc === 'true' ? 'asc' : 'desc';
  }
  // exclude key param from request
  delete _filtersWithValues.key;
  dispatch(fetchInventory());
  try {
    const { data: response } = await getInventory({
      ..._filtersWithValues,
      _order,
      _sort,
      _pageNumber,
      _pageSize,
    });
    const data = response;
    const processedResponse = { data };

    const fetchSuccess =
      pageNumber > 1 ? fetchInventoryLoadMoreSuccess : fetchInventorySuccess;
    return dispatch(fetchSuccess(processedResponse));
  } catch (errorMsg) {
    return dispatch(fetchInventoryError(errorMsg));
  }
};
