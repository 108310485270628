import useFilterValues from 'hooks/useFilterValues';
import { useCountryOptions, getStates, getCities, useShopOptions, useLocationOptions } from 'constants/filters.constants';
import { FilterGroupItem } from '@ui5/webcomponents-react';
import MultiComboBoxField from 'components/MultiComboBoxField';
import PropTypes from 'prop-types';
import * as S from './styled';
import { FILTER_NAMES } from './FiltersForm.config';
import { useSelector } from 'react-redux';
import {filtersSelector} from './state/filters.reducer';

const FilterForm = ({ onSubmit, onReset }) => {

  const {
    addMultiInputTokenToFilter,
    getFilterValues,
    resetFilterValues,
    addMultiComboBoxValuesToTokenState,
    tokenValues,
  } = useFilterValues();

  const handleFormReset = () => {
    resetFilterValues();
    onReset();
  };

  const handleSubmission = () => {
    const formValuesViaTokens = getFilterValues();
    // this will trigger a change on queryParams and therefore make an api call
    formValuesViaTokens.key = Date.now();
    Promise.resolve(onSubmit(formValuesViaTokens));
  };

  const countryOptions = useCountryOptions();
  const filtersValues = useSelector(filtersSelector);
  const countriesSelected = filtersValues.country;
  const statesSelected = filtersValues.state;
  const shopOptions = useShopOptions();
  const locationOptions = useLocationOptions();

  return (
    <>
      <S.FilterBar
        showClearOnFB
        onClear={handleFormReset}
        showGoOnFB
        onGo={handleSubmission}
        hideToggleFiltersButton
        filterContainerWidth="25rem"
      >
        {/* <FilterGroupItem label={'Country'}>
          <MultiComboBoxField
            name="country"
            testid="countryFilter"
            options={countryOptions}
            addTokenToFilter={addMultiInputTokenToFilter}
            addMultiComboBoxValuesToTokenState={addMultiComboBoxValuesToTokenState}
            tokenValues={tokenValues[FILTER_NAMES.country]}
          />
        </FilterGroupItem>

        <FilterGroupItem label={'State'}>
          <MultiComboBoxField
            name="state"
            testid="stateFilter"
            options={getStates(countriesSelected)}
            addTokenToFilter={addMultiInputTokenToFilter}
            addMultiComboBoxValuesToTokenState={addMultiComboBoxValuesToTokenState}
            tokenValues={tokenValues[FILTER_NAMES.state]}
          />
        </FilterGroupItem>

        <FilterGroupItem label={'City'}>
          <MultiComboBoxField
            name="city"
            testid="cityFilter"
            options={getCities(statesSelected)}
            addTokenToFilter={addMultiInputTokenToFilter}
            addMultiComboBoxValuesToTokenState={addMultiComboBoxValuesToTokenState}
            tokenValues={tokenValues[FILTER_NAMES.city]}
          />
        </FilterGroupItem> */}

        <FilterGroupItem label={'Shop Location'}>
          <MultiComboBoxField
            name="location"
            testid="locationFilter"
            options={locationOptions}
            addTokenToFilter={addMultiInputTokenToFilter}
            addMultiComboBoxValuesToTokenState={addMultiComboBoxValuesToTokenState}
            tokenValues={tokenValues[FILTER_NAMES.location]}
          />
        </FilterGroupItem>
      </S.FilterBar>
    </>
  );
};

FilterForm.propTypes = {
  defaultValues: PropTypes.shape({}).isRequired,
  validationSchema: PropTypes.shape({}),
  onSubmit: PropTypes.func,
  onReset: PropTypes.func,
  resetValues: PropTypes.shape({}),
};

FilterForm.defaultProps = {
  resetValues: null,
  onSubmit: null,
  onReset: null,
  validationSchema: null,
};

export default FilterForm;
