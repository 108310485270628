import './public-path';
import eurekaMgrs from '@eureka/ui-managers';
import {
  setCustomElementsScopingRules,
  setCustomElementsScopingSuffix,
} from '@ui5/webcomponents-base/dist/CustomElementsScope';
import '@ui5/webcomponents-localization/dist/Assets-static';
import '@ui5/webcomponents-localization/dist/Assets';
// eslint-disable-next-line import/extensions
import { setLanguage as setUi5Language } from '@ui5/webcomponents-base/dist/config/Language.js';
import '@ui5/webcomponents-react/dist/Assets';
import './assets/icons';
import eureka from 'eureka';
import ReactDOM from 'react-dom';
import App from './App';
import './assets/illustrations';

/* istanbul ignore next */
if (process.env.NODE_ENV !== 'test') {
  setCustomElementsScopingSuffix(process.env.APP_NAME);
  setCustomElementsScopingRules({
    include: [/^ui5-/],
    exclude: [/^ui5-object-page-anchor-tab/],
  });
}

const { eventBus, ConfigManager } = eurekaMgrs;
const { getLanguage } = ConfigManager;

eventBus.on('i18n-update', langCode => {
  // for i18n
  eureka.I18nProvider.setLanguage(langCode);
  setUi5Language(eureka.I18nProvider.getUi5Language(langCode));
});

export const windowLoaded = () => {
  eurekaMgrs.AppManager.renderApp(process.env.APP_NAME, 'container');
  eventBus.emit('i18n-initialize', null, 'en-US');
};

if (window instanceof Window) {
  window.onload = windowLoaded;
}

export const initializeI18nForNative = () => {
  eureka.I18nProvider.initI18n(
    {
      shell: 'http://localhost:2006',
      [process.env.APP_NAME]: `http://localhost:${process.env.PORT}`,
    },
    {
      debug: false,
      lowerCaseLng: false,
      defaultNS: process.env.APP_NAME,
      ns: ['shell', process.env.APP_NAME],
      lng: getLanguage() || 'en-US',
      fallbackLng: false,
      fallback: false,
    },
  );
};

const renderI18n = props => {
  /* istanbul ignore else */
  if (window instanceof Window) {
    initializeI18nForNative();
  } else {
    const configJson = props.config;
    const appName = `${process.env.APP_NAME}-ui`;
    const pathName = `http://localhost:${process.env.PORT}`;
    setUi5Language(eureka.I18nProvider.getUi5Language(getLanguage()));
    eureka.I18nProvider.initI18n(
      {
        shell: configJson['shell-ui'].location || `http://localhost:${configJson['shell-ui'].port}`,
        [process.env.APP_NAME]: `${configJson.components.find(item => item.name === appName)
          .location || pathName}`,
      },
      {
        debug: true,
        lowerCaseLng: false,
        defaultNS: process.env.APP_NAME,
        ns: ['shell', process.env.APP_NAME],
        lng: getLanguage() || 'en-US',
        fallbackLng: false,
        fallback: false,
      },
    );
  }
};

ReactDOM.render(<App />, document.getElementById('inv-shell-ui-app'));
