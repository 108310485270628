import INITIAL_FILTERS_STATE from './initialState';

export const SET_INPUT_VALUE = 'SET_INPUT_VALUE';
export const ADD_MULTI_INPUT_TOKEN = 'ADD_MULTI_INPUT_TOKEN';
export const REMOVE_TOKEN = 'REMOVE_TOKEN';
export const RESET_FILTERS = 'RESET_FILTERS';
export const ADD_MULTI_COMBO_BOX_TOKENS = 'ADD_MULTI_COMBO_BOX_TOKENS';

const reducer = (state = INITIAL_FILTERS_STATE, action = null) => {
  switch (action.type) {
    case SET_INPUT_VALUE: {
      const { name, value } = action.payload;
      return {
        ...state,
        [name]: value,
      };
    }
    case ADD_MULTI_INPUT_TOKEN: {
      const { name, value } = action.payload;
      return {
        ...state,
        [name]: [...state[name], value],
      };
    }

    case ADD_MULTI_COMBO_BOX_TOKENS: {
      const { name, values } = action.payload;
      return {
        ...state,
        [name]: [...values],
      };
    }

    case REMOVE_TOKEN: {
      const { name, value } = action.payload;
      const currentValues = [...state[name]];
      if (currentValues && currentValues.length > 0) {
        const toBeDeletedIndex = currentValues.indexOf(value);
        if (toBeDeletedIndex > -1) {
          currentValues.splice(toBeDeletedIndex, 1);
        }
      }
      return {
        ...state,
        [name]: [...currentValues],
      };
    }

    case RESET_FILTERS: {
      return {
        ...state,
        ...INITIAL_FILTERS_STATE,
      };
    }

    default: {
      return state;
    }
  }
};

export const filtersSelector = state => state.inventory.filters;

export default reducer;
