import eureka from 'eureka';
import { useCallback } from 'react';

const { useTranslation } = eureka.I18nProvider;

const useTranslate = () => {
  const { t, i18n } = useTranslation();

  return useCallback(
    (key, variable) => {
      if (i18n.isInitialized) {
        return t(`${process.env.APP_NAME}:${key}`, variable);
      }
      return t(key, variable);
    },
    [i18n, t],
  );
};

export default useTranslate;
