import { FilterBar as UIFilterBar } from '@ui5/webcomponents-react';
// eslint-disable-next-line no-restricted-imports
import styled from 'styled-components';

const FilterBar = styled(UIFilterBar)`
  &[class^='FilterBar-outerContainer'] {
    padding: 0;
    box-shadow: none;
  }
  & ui5-input[placeholder='Search'] {
    width: 100%;
  }
  & [class^='ToolbarSeparator-separator'] {
    display: none;
  }
`;

export default FilterBar;
