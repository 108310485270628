import eureka from 'eureka';
import { stringify } from 'qs';
import { INVENTORY_ENDPOINT } from './inventory.api.config';
import responseMock from '../test/mocks/responseMock.json';

const { axiosCreate } = eureka.HttpWrapper;
const paramsSerializer = params => stringify(params, { arrayFormat: 'repeat', skipNulls: true });

const getHeaders = () => {
  if (process.env.APP_BASE_URL.includes('localhost')) {
    return {
      'X-Tenant-ID': '1',
      'X-User-ID': '1',
      'Content-Type': 'application/json',
    };
  }
  return { 'Content-Type': 'application/json' };
};

const headers = getHeaders();
export const api = axiosCreate(
  {
    headers,
    baseURL: 'https://api.internal.stbx-poc.osta-sandbox.eurekacloud.io/inventory-aggregation-svc/business/v1/',
    paramsSerializer,
  },
  0,
).instance;

export const getInventory = params => {
  const location = params.location;
  const selectedLocation = location?location[0]:null;
  const path = selectedLocation ? "/"+ INVENTORY_ENDPOINT + "/" + selectedLocation : "/"+ INVENTORY_ENDPOINT;
  return api.get(path);
  return {data:responseMock};
  const result = responseMock.map(item => {
    return {
      ...item,
      quantity: Math.floor(Math.random() * 10000),
      initialQuantity: Math.floor(Math.random() * 10000),
      executedDateTime: new Date().toISOString(),
      lastRestockDate: new Date().toISOString()
    };
  });
  return Promise.resolve({ data: result });
};
