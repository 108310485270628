import {
  FlexBox,
  Title,
  Card,
  Text,
  Label,
  ProgressIndicator,
  ValueState,
  FlexBoxDirection,
  FlexBoxAlignItems,
  FlexBoxJustifyContent,
  Icon,
  ObjectStatus
} from '@ui5/webcomponents-react';
import PropTypes from 'prop-types';
import { formatDistance } from 'date-fns';
import '@ui5/webcomponents-icons/dist/status-critical.js';
import '@ui5/webcomponents-icons/dist/status-negative.js';
import '@ui5/webcomponents-icons/dist/status-positive';
import '@ui5/webcomponents-icons/dist/status-in-process.js';

const InventoryCard = ({ item }) => {
  const timestamp = typeof item._ts === 'number'? item._ts*1000 : null;
  const date = timestamp ? formatDistance(new Date(timestamp), new Date(), {
    addSuffix: true,
    includeSeconds: true
  }):'-';

  const onHandInventory = item.quantity;
  const inventoryPercentage = (onHandInventory) * 100/item.totalUnitsReceived;
  let valueState = ValueState.Success;
  let onHandInventoryColor = 'green';
  if (inventoryPercentage >= 0 && inventoryPercentage < 10) {
    valueState = ValueState.Error;
    onHandInventoryColor = 'crimson'
  } else if (inventoryPercentage >= 10 && inventoryPercentage <= 25 ) {
    valueState = ValueState.Warning;
    onHandInventoryColor = 'black'
  }
 
  
  return (
    <Card style={{ margin: 'auto', padding: '1rem', display: 'inline' }}>
      <FlexBox
        direction={FlexBoxDirection.Row}
        alignItems={FlexBoxAlignItems.Center}
        justifyContent={FlexBoxJustifyContent.SpaceBetween}
        style={{ margin: 'auto', padding: '1.2rem' }}
      >
        <FlexBox direction={FlexBoxDirection.Column} alignItems={FlexBoxAlignItems.Start}>
          <FlexBox  direction={FlexBoxDirection.Column}  alignItems={FlexBoxAlignItems.Start} justifyContent={FlexBoxJustifyContent.End}>
            <Title level="H4" style={{width:'12rem'}}>{item.productName}</Title>
            <Title level="H6" style={{width:'12rem' }}>({item.id})</Title>
        </FlexBox>
          <Title></Title>
          <FlexBox direction={FlexBoxDirection.Row} alignItems={FlexBoxAlignItems.Center}>
          {/* <div>{Math.floor(inventoryPercentage)>100?100:Math.floor(inventoryPercentage)}%</div> */}
          <ProgressIndicator style={{minWidth: '12rem'}}
            valueState={valueState}
            value={inventoryPercentage}
          />
           {(valueState==ValueState.Warning || valueState==ValueState.Error) && 
          <ObjectStatus style={{'margin':'0.5rem'}} state={valueState} icon={ <Icon name={'status-in-process'} valueState={valueState} />}/>}
          </FlexBox>
        </FlexBox>

        <FlexBox direction="Column" alignItems={FlexBoxAlignItems.Start} style={{"marginLeft": '.5rem' }}>
          <Text style={{ color:onHandInventoryColor, fontSize: '1.6em' }}>
            {Math.round(onHandInventory*100)/100}
          </Text>
          <Label style={{ fontSize: '1em' }}>{'On hand inventory (Quantity)'}</Label>
        </FlexBox>

        <FlexBox direction="Column">
          <Text style={{ fontSize: '1.6em' }}>
            {Math.round(item.totalUnitsReceived*100)/100}
          </Text>
          <Label style={{ fontSize: '1em' }}>{'Total quantity received'}</Label>
        </FlexBox>

        <FlexBox direction="Column">
          <Label showColon style={{ fontSize: '1.2em' }}>{'Last updated'}</Label>
          <Text style={{ fontSize:'1.2em', whiteSpace: 'nowrap' }}>{date}</Text>
        </FlexBox>

        <FlexBox direction="Column">
          <Label showColon style={{ fontSize: '1.2em' }}>{'Last restock'}</Label>
          <Text style={{ fontSize:'1.2em', whiteSpace: 'nowrap' }}>
            {new Date(item.lastRestockDate).toLocaleString()}
          </Text>
        </FlexBox>
      </FlexBox>
    </Card>
  );
};

InventoryCard.propTypes = {
  item: PropTypes.object.isRequired,
};

export default InventoryCard;
