import { IllustratedMessage, IllustrationMessageType } from '@ui5/webcomponents-react';
import '@ui5/webcomponents-fiori/dist/illustrations/UnableToLoad.js';
import '@ui5/webcomponents-fiori/dist/illustrations/NoData.js';

const NoDataComponent = () => {
  return (
    <IllustratedMessage
      data-testid="no-data-table"
      name={IllustrationMessageType.NoData}
      titleText={'There is no data available'}
    />
  );
};

export default NoDataComponent;
