import {
  SET_INPUT_VALUE,
  ADD_MULTI_INPUT_TOKEN,
  REMOVE_TOKEN,
  RESET_FILTERS,
  ADD_MULTI_COMBO_BOX_TOKENS,
} from './filters.reducer';

export const setInputValue = payload => ({
  type: SET_INPUT_VALUE,
  payload,
});
export const addMultiInputToken = payload => ({
  type: ADD_MULTI_INPUT_TOKEN,
  payload,
});

export const addMultiComboBoxTokens = payload => ({
  type: ADD_MULTI_COMBO_BOX_TOKENS,
  payload,
});

export const removeToken = payload => ({
  type: REMOVE_TOKEN,
  payload,
});

export const resetFilters = () => ({
  type: RESET_FILTERS,
});
