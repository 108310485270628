import { useState, useCallback, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import useQueryString from 'hooks/useQueryString';
import { calculatePageNumber } from 'utils/calculatePageNumber.utils';
import { inventorySelector } from 'features/Inventory/Table/state/Table.selectors';
import { DEFAULT_PAGINATION } from '../constants/app.constants';

export const useInventoryTable = ({ isLoading = false }) => {
  const { content: items, totalElements: totalCount } = useSelector(
    inventorySelector,
  );
  const reactTableOptions = useMemo(
    () => ({
      manualSortBy: true,
      autoResetSortBy: false,
      autoResetSelectedRows: false,
    }),
    [],
  );
  const { setQueryParams } = useQueryString();

  const handleLoadMore = useCallback(
    ({ detail: { rowCount } }) => {
      if (rowCount > 0) {
        const pageNum = calculatePageNumber(rowCount, items.length, totalCount);
        if (pageNum) {
          setQueryParams({ pageNumber: pageNum, pageSize: DEFAULT_PAGINATION.PAGE_SIZE });
          return pageNum;
        }
      }
      return null;
    },
    [items.length, setQueryParams, totalCount],
  );

  const tableRef = useRef();

  const configProps = {
    loading: isLoading,
    minRows: 1,
    infiniteScrollThreshold: 5,
    infiniteScroll: true,
    onLoadMore: handleLoadMore,
    reactTableOptions,
    ref: tableRef,
  };

  return {
    configProps,
  };
};
