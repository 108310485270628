import PropTypes from 'prop-types';
import { MultiComboBox, MultiComboBoxItem } from '@ui5/webcomponents-react';

const MultiComboBoxField = ({
  name,
  testid,
  options,
  addMultiComboBoxValuesToTokenState,
  tokenValues,
}) => {
  const handleSelectionChange = e => {
    addMultiComboBoxValuesToTokenState(
      e.detail.items.map(item => item.id),
      name,
    );
  };
  const getOptionSelected = option => tokenValues?.includes(option);

  return (
    <MultiComboBox name={name} data-testid={testid} onSelectionChange={handleSelectionChange}>
      {options.map(option => (
        <MultiComboBoxItem
          id={option?.id}
          key={Object.prototype.hasOwnProperty.call(option, 'id') ? option.id : option}
          text={option.value}
          selected={getOptionSelected(option.id)}
          data-testid={`${testid}-combobox-item-${
            Object.prototype.hasOwnProperty.call(option, 'id') ? option.id : option
          }`}
        />
      ))}
    </MultiComboBox>
  );
};

MultiComboBoxField.propTypes = {
  name: PropTypes.string.isRequired,
  testid: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      }),
      PropTypes.string,
    ]),
  ),
  addMultiComboBoxValuesToTokenState: PropTypes.func.isRequired,
  tokenValues: PropTypes.array,
};

MultiComboBoxField.defaultProps = {
  options: [],
  tokenValues: [],
};

export default MultiComboBoxField;
