export const DEFAULT_VALUES = {
  country: [],
  state: [],
  city: [],
  shop: [],
  location: [],
};

export const FILTER_NAMES = {
  country: 'country',
  state: 'state',
  city: 'city',
  shop: 'shop',
  location: 'location'
};
