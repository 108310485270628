import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { parse, stringify } from 'qs';

const PARSE_OPTIONS = {
  ignoreQueryPrefix: true,
};

export default () => {
  const { push, replace, location } = useHistory();
  const queryParams = useMemo(() => parse(location.search, PARSE_OPTIONS), [location.search]);
  const getQueryParams = useCallback(() => queryParams, [queryParams]);

  const setQueryParams = useCallback(
    (params, newPathname) => {
      const newLocation = {
        pathname: newPathname || location.pathname,
        search: stringify(
          {
            ...parse(location.search, PARSE_OPTIONS),
            ...params,
          },
          { skipNulls: true },
        ),
      };
      return newPathname ? push(newLocation) : replace(newLocation);
    },
    [push, replace, location],
  );
  return { getQueryParams, setQueryParams };
};
