import { useMemo } from 'react';

export const useCountryOptions = () => {
  return useMemo(
    () => [
      { id: 'canada', value: 'Canada' },
      { id: 'usa', value: 'USA' },
    ],
    [],
  );
};

const statesMap = new Map([
  [
    'canada',
    [
      { id: 'ab', value: 'Alberta' },
      { id: 'bc', value: 'British Columbia' },
      { id: 'qc', value: 'Quebec' },
    ],
  ],
  [
    'usa',
    [
      { id: 'phi', value: 'Philadelphia' },
      { id: 'ny', value: 'New York' },
    ],
  ],
]);

const citiesMap = new Map([
  [
    'ab',
    [
      { id: 'cal', value: 'Calgary' },
      { id: 'edm', value: 'Edmonton' },
    ],
  ],
  ['bc', [{ id: 'van', value: 'Vancouver' }]],
  ['qc', [{ id: 'mtl', value: 'Montreal' }]],
  ['phi', [{ id: 'spr', value: 'Springfield' }]],
  ['ny', [{ id: 'nyc', value: 'New York City' }]],
]);

export const getStates = country => {
  return country[0] ? statesMap.get(country[0]) : [];
};

export const getCities = state => {
  return state[0] ? citiesMap.get(state[0]) : [];
};

export const useShopOptions = () => {
  return useMemo(
    () => [
      { id: '1', value: 'Shop1' },
      { id: '2', value: 'Shop2' },
      { id: '3', value: 'Shop3' },
      { id: '4', value: 'Shop4' },
    ],
    [],
  );
};

export const useLocationOptions = () => {
  const locationsList = [
    "YCBS",
    "YAOE",
    "YECW",
    "YCKI",
    "YCYK",
    "YBKH",
    "YABT",
    "YDMD",
    "YDJX",
    "YBLL",
    "YBOH",
    "YEJV",
    "YBRM",
    "YDFV",
    "YDRF",
    "YDUB",
    "YDJE",
    "YCYX",
    "YBYT",
    "YBJE",
  ];
  return useMemo(
    () => {
      return locationsList.map((location) => 
         ({
          id:location,
          value: location
        })
      )
    }
  )
}
