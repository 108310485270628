import { DynamicPage, DynamicPageHeader, DynamicPageTitle, Label, Title } from '@ui5/webcomponents-react';
import PropTypes from 'prop-types';
import BusyIndicator from 'components/BusyIndicator';
import { useSelector } from 'react-redux';
import FiltersForm from '../FiltersForm';
import { useInventoryTable } from 'hooks/useInventoryTable';
import Table from '../Table';
import { inventorySelector } from '../Table/state/Table.selectors';

const ContainerPage = ({ filters }) => {

  const { isLoading } = useSelector(inventorySelector);
  const tableObject = useInventoryTable({
    isLoading,
  });

  return (
    <BusyIndicator active={isLoading}>
      <DynamicPage
        headerTitle={
          <DynamicPageTitle
            header={'DEMO'}
            subHeader={'Internal use only'}>
          </DynamicPageTitle>
        }
        headerContent={
          <DynamicPageHeader>
            <FiltersForm
              filters={filters}
            />
          </DynamicPageHeader>
        }
        >
        <Table
         tableObject={tableObject}
        />
      </DynamicPage>
    </BusyIndicator>
  );
};

ContainerPage.propTypes = {
  filters: PropTypes.shape({}),
};

ContainerPage.defaultProps = {
  filters: null,
};

export default ContainerPage;
