import { combineReducers } from 'redux';
import inventoryReducer from 'features/Inventory/state';
import globalConfigReducer from './globalConfig/globalConfig.reducer';

const reducerMap = {
  globalConfig: globalConfigReducer,
  inventory: inventoryReducer,
};

export default combineReducers(reducerMap);
